import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  ka: {
    translation: {
      'About us': 'ჩვენ შესახებ',
    },
  },
  en: {
    translation: {
      'About us': 'About us',
    },
  },
};

i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // resources,
    debug: true,
    returnEmptyString: false,
    parseMissingKeyHandler: (key) => key,
    whitelist: ['ka', 'en'],
    lng: localStorage.getItem('language_') || 'ka',
    fallbackLng: 'en',
    // loadPath: '/locales/{{lng}}/{{ns}}.json',
    // load:"currentOnly",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  });

window.i18n = i18n;
export default i18n;
