import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { Icon } from "./simple/Icon";
import logo2 from "../../assets/img/logo2.svg";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-inner">
                <div className="lf">
                  <Link to="/">
                    <img src={logo2} alt="hyundai-footer-logo" />
                  </Link>
                  <a
                    href="https://www.hyundai.com/worldwide/en"
                    target="_blank"
                  >
                    <Icon
                      name="globe-icon"
                      width="20"
                      height="20"
                      view="0 0 20 20"
                    />
                    Hyundai Worldwide
                  </a>
                </div>
                <div className="rg">
                  <div className="col-item">
                    <h4>
                      <Trans i18nKey="Press Center" />
                    </h4>
                    <ul>
                      <li>
                        <Link to="/news">
                          <Trans i18nKey="News" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/service">
                          <Trans i18nKey="Services" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact">
                          <Trans i18nKey="Contact" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-item">
                    <h4>
                      <Trans i18nKey="Cars" />
                    </h4>
                    <ul>
                      <li>
                        <Link to="/models">
                          <Trans i18nKey="Configuration" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/test-drive">
                          <Trans i18nKey="Test Drive" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/tradein">
                          <Trans i18nKey="Trade In" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-item">
                    <h4>
                      <Trans i18nKey="About Hyundai" />
                    </h4>
                    <ul>
                      <li>
                        <Link to="/about">
                          <Trans i18nKey="Brand history" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/about">
                          <Trans i18nKey="Brand philosophy" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/about">
                          <Trans i18nKey="About Showroom" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-item">
                    <h4>
                      <Trans i18nKey="Offers" />
                    </h4>
                    <ul>
                      <li>
                        <Link to="/offers">
                          <Trans i18nKey="Products and Offers" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/offers/garantia">
                          <Trans i18nKey="Warranty" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-item">
                    <h4>
                      <Trans i18nKey="Contact" />
                    </h4>
                    <ul>
                      <li>
                        <a href="tel:032 2 51 77 99" className="phone-item">
                          <span className="phone">
                            <Icon
                              name="phone-icon"
                              width="14.968"
                              height="15"
                              view="0 0 14.968 15"
                            />
                          </span>
                          2 51 77 99
                        </a>
                      </li>
                      <li>
                        <div className="lab">
                          <Trans i18nKey="Follow" />
                        </div>
                        <div className="socials">
                          <ul>
                            <li>
                              <a
                                href="https://www.facebook.com/hyundai.ge/"
                                target="_blank"
                              >
                                <Icon
                                  name="facebook-icon"
                                  width="6"
                                  height="12"
                                  view="0 0 6 12"
                                />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.instagram.com/hyundaigeo/"
                                target="_blank"
                              >
                                <Icon
                                  name="instagram-icon"
                                  width="13.997"
                                  height="13.994"
                                  view="0 0 13.997 13.994"
                                />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.youtube.com/channel/UC1JRWHRI7uhByPmG33olihw"
                                target="_blank"
                              >
                                <Icon
                                  name="youtube-icon"
                                  width="15"
                                  height="10.55"
                                  view="0 0 15 10.55"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
