import { all, call } from 'redux-saga/effects';
import SliderSaga from './slider.saga';
import NewsSaga from './news.saga';
import CarsSaga from './cars.saga';
import ServicesSaga from "./serveces.saga"

function* rootSaga() {
  yield all([call(SliderSaga), call(NewsSaga), call(CarsSaga),call(ServicesSaga)]);
}

export default rootSaga;
