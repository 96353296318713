import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from './simple/Icon';
import Select from 'react-select';

export function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [{ languages, selected }, setState] = useState({
    languages: [
      {
        label: 'ქარ',
        value: 'ka',
      },
      {
        label: 'Eng',
        value: 'en',
      },
    ],
    selected:
      i18n.language === 'ka'
        ? {
            label: 'ქარ',
            value: 'ka',
          }
        : {
            label: 'Eng',
            value: 'en',
          },
  });

  const changeLanguage = useCallback(
    (selected) => {
      localStorage.setItem('language_', selected.value);
      i18n.changeLanguage(selected.value);
      setState((prevState) => ({
        ...prevState,
        selected,
      }));
    },
    [i18n]
  );

  return (
    <div className='language-picker'>
      {/* <div className='selected-lang-box'>
        <span className='icon-lang'>
          <Icon name='language' view='0 0 16 16' width='16' height='16' />
        </span>
      </div>
      <Select
        value={selected}
        options={languages}
        onChange={changeLanguage}
        // menuIsOpen={true}
        isSearchable={false}
        classNamePrefix='lang'
      /> */}
    </div>
  );
}
