import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import { LanguageSwitcher } from "./LanguageSwitcher";
import logo from "../../assets/img/logo.svg";
import { Icon } from "./simple/Icon";
import i18n from "../../i18n";
import { useCallback } from "react";
import { fetchSearchHome } from "../../api/news.api";
import { staticBaseUrl } from "../../helper/baseUrl";
import Subnav from "./Subnav/Subnav";
import "./Subnav/Subnav.css";
import { CurrencySwitch } from "../currencySwitch/CurrencySwitch";

export default function HHeader() {
  const navigate = useNavigate();
  const [
    { mMenuIsOpen, activeSearch, search, items, subNavOpen, burgerSubNavOpen },
    setState
  ] = useState({
    mMenuIsOpen: false,
    activeSearch: false,
    search: "",
    items: [],
    isLoading: false,
    subNavOpen: false,
    burgerSubNavOpen: false
  });

  /**
   * Methods
   */
  const handleOpenMobileMenu = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      mMenuIsOpen: true
    }));
  }, []);

  const handleCloseMobileMenu = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      mMenuIsOpen: false
    }));
  }, []);
  const handelOpenSearch = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      activeSearch: true
    }));
  }, []);
  const handelCloseSearch = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      activeSearch: false
    }));
  }, []);
  const handelChangeSearch = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  const handleGoToRedirect = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      search: "",
      mMenuIsOpen: false,
      activeSearch: false
    }));
    navigate("/models");
  }, []);

  const loadSearch = useCallback(async () => {
    if (search && search.length < 3) return;
    try {
      const { data } = await fetchSearchHome(search);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        items: data
      }));
    } catch (error) {}
  }, [search]);

  useEffect(() => {
    let width = window.width;
    if (width < 991) {
      console.log(width);
    }
    loadSearch();
  }, [loadSearch]);

  const handleMouseEnter = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      subNavOpen: true
    }));
  }, []);

  const closeSubNav = () => {
    setState((prevState) => ({
      ...prevState,
      subNavOpen: false
    }));
  };

  const handleBurgerClick = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      burgerSubNavOpen: true
    }));
  }, []);

  const closeBurgerSubNav = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      burgerSubNavOpen: false
    }));
  }, []);

  return (
    <>
      <div className="main-header">
        <div className="header-wrapper">
          <div className="top-bar">
            <div className="container">
              <div className="row">
                <div className="col-md-12 ">
                  <div className="top-bar-inner">
                    <LanguageSwitcher />
                    <div className="rg-elements">
                      <a href="tel:032 2 51 77 99" className="phone-item">
                        <span className="phone">
                          <Icon
                            name="phone-icon"
                            width="14.968"
                            height="15"
                            view="0 0 14.968 15"
                          />
                        </span>
                        2 51 77 99
                      </a>
                      <div className="socials">
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/hyundai.ge/"
                              target="_blank"
                            >
                              <Icon
                                name="facebook-icon"
                                width="6"
                                height="12"
                                view="0 0 6 12"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/hyundaigeo/"
                              target="_blank"
                            >
                              <Icon
                                name="instagram-icon"
                                width="13.997"
                                height="13.994"
                                view="0 0 13.997 13.994"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.youtube.com/channel/UC1JRWHRI7uhByPmG33olihw"
                              target="_blank"
                            >
                              <Icon
                                name="youtube-icon"
                                width="15"
                                height="10.55"
                                view="0 0 15 10.55"
                              />
                            </a>
                          </li>
                          <li>
                            <CurrencySwitch />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-bar">
            <div className="container">
              <div className="row">
                <div className="col-md-12 position-inherit">
                  <div className="nav-bar-inner">
                    <div className="brand-logo">
                      <div>
                        <Link to="/">
                          <img src={logo} alt="logo" />
                        </Link>
                      </div>
                    </div>
                    <div className="responsive-currency">
                      <CurrencySwitch />
                    </div>
                    <div className="rg-elements">
                      <div className="menu-bar">
                        <ul>
                          <li>
                            <NavLink to="/about" className={(navData) => (navData.isActive ? "active" : '')}>
                              <Trans i18nKey="About Us" />
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/models" className={(navData) => (navData.isActive ? "active" : '')}>
                              <Trans i18nKey="Models" />
                              <span className="arrow-down">
                                <Icon
                                  name="arrow-down"
                                  width="8.717"
                                  height="5.386"
                                  view="0 0 8.717 5.386"
                                />
                              </span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/service" className={(navData) => (navData.isActive ? "active" : '')}>
                              <Trans i18nKey="Services" />
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="/news" className={(navData) => (navData.isActive ? "active" : '')}>
                              <Trans i18nKey="News" />
                            </NavLink>
                          </li>
                          <li onMouseEnter={handleMouseEnter}>
                            <NavLink to="/tradein" className={(navData) => (navData.isActive ? "active" : '')}>
                              <Trans i18nKey="Trade In" />
                              <span className="arrow-down">
                                <Icon
                                  name="arrow-down"
                                  width="8.717"
                                  height="5.386"
                                  view="0 0 8.717 5.386"
                                />
                              </span>
                            </NavLink>
                            {subNavOpen && (
                              <Subnav
                                className="inner-subnav"
                                burgerCloseBtn={true}
                                onMouseEnter={handleMouseEnter}
                              />
                            )}
                          </li>
                          <li>
                            <NavLink to="/offers" className={(navData) => (navData.isActive ? "active" : '')}>
                              <Trans i18nKey="Products and Offers" />
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="/contact" className={(navData) => (navData.isActive ? "active" : '')}>
                              <Trans i18nKey="Contact" />
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`mobile-menu-buttons ${
                          mMenuIsOpen && "active"
                        }`}
                      >
                        {!mMenuIsOpen ? (
                          <div
                            className="menu-btn"
                            onClick={handleOpenMobileMenu}
                          >
                            <Icon
                              name="burger-menu"
                              width="24"
                              height="20"
                              view="0 0 24 20"
                            />
                          </div>
                        ) : (
                          <div
                            className="close-btn"
                            onClick={handleCloseMobileMenu}
                          >
                            <Icon
                              name="close-btn-icon"
                              width="12.729"
                              height="12.729"
                              view="0 0 12.729 12.729"
                            />
                          </div>
                        )}
                      </div>
                      <div className="search-box" onClick={handelOpenSearch}>
                        <span className="search-icon">
                          <Icon
                            name="search-icon"
                            width="15.864"
                            height="15.9"
                            view="0 0 15.864 15.9"
                          />
                        </span>
                        {/* <input type='text' name='search' value={search} placeholder={i18n.t('Search')} readOnly /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {subNavOpen && (
          <Subnav
            className="sub-nav-background"
            closeSubNav={closeSubNav}
            burgerCloseBtn={true}
            onMouseEnter={handleMouseEnter}
          />
        )}
      </div>

      {/*--Mobile menu */}
      <div
        className="mobile-menu"
        style={{
          display: `${mMenuIsOpen ? "block" : "none"}`
        }}
      >
        <ul>
          {!burgerSubNavOpen ? (
            <ul>
              <li>
                <NavLink to="/about" className={(navData) => (navData.isActive ? "active" : '')}>
                  <Trans i18nKey="About Us" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/models" className={(navData) => (navData.isActive ? "active" : '')}>
                  <Trans i18nKey="Models" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/service" className={(navData) => (navData.isActive ? "active" : '')}>
                  <Trans i18nKey="Services" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/news" className={(navData) => (navData.isActive ? "active" : '')}>
                  <Trans i18nKey="News" />
                </NavLink>
              </li>
              <li style={{ display: "flex" }}>
                <NavLink to="/tradein" className={(navData) => (navData.isActive ? "active" : '')}>
                  <Trans i18nKey="Trade In" />
                </NavLink>
                <span onClick={handleBurgerClick} className="subnav-next-arrow">
                  <Icon
                    name="arrow-down"
                    width="17.717"
                    height="15.386"
                    view="0 0 8.717 5.386"
                  />
                </span>
              </li>
              <li>
                <NavLink to="/offers" className={(navData) => (navData.isActive ? "active" : '')}>
                  <Trans i18nKey="Products and Offers" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" className={(navData) => (navData.isActive ? "active" : '')}>
                  <Trans i18nKey="Contact" />
                </NavLink>
              </li>
            </ul>
          ) : (
            <Subnav closeSubNav={closeBurgerSubNav} />
          )}
        </ul>
      </div>
      {/* Fullwidth search*/}
      <div className={`fullwidth-search-wrapper ${activeSearch && "active"}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="fullwidth-search">
                <div className="search-box">
                  <span className="search-icon">
                    <Icon
                      name="search-icon"
                      width="15.864"
                      height="15.9"
                      view="0 0 15.864 15.9"
                    />
                  </span>
                  <input
                    type="text"
                    name="search"
                    placeholder={i18n.t("Search")}
                    value={search}
                    onChange={handelChangeSearch}
                  />
                </div>
                <div className="close-btn" onClick={handelCloseSearch}>
                  <Icon
                    name="close-btn-icon"
                    width="12.729"
                    height="12.729"
                    view="0 0 12.729 12.729"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="search-result"
          style={{
            display: `${search.length > 2 ? "block" : "none"}`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="search-list-result-inner">
                  <div className="result-list">
                    {items.map((item) => (
                      <Link
                        key={`seaerch-item-${item.id}`}
                        to={`/models/${item.slugURL}`}
                        className="result-item"
                      >
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url('${staticBaseUrl}${item.logo}')`
                          }}
                        ></div>
                        <div className="txt-content">
                          <span className="subtitle">{item.title}</span>
                          <h4>
                            {item.model} {item.year}
                          </h4>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <a
                    to="/models"
                    className="full-list"
                    onClick={handleGoToRedirect}
                  >
                    <Trans i18nKey="All" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
