import { SET_SLIDER, SET_SLIDER_LOADING } from '../const/slider.const';

const initialState = {
  isLoading: true,
  items: [],
};

export default function sliderReducr(state = initialState, action) {
  switch (action.type) {
    case SET_SLIDER_LOADING: {
      state = {
        ...state,
        isLoading: action.payload,
      };
      break;
    }
    case SET_SLIDER: {
      state = {
        ...state,
        items: action.payload,
        isLoading: false,
      };
      break;
    }
    default:
      return state;
  }
  return state;
}
