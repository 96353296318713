import { SET_NEWS, SET_NEWS_LOADING, SET_PROMOS, SET_PROMOS_LOADING } from '../const/news.const';

const initialState = {
  isLoading: true,
  items: [],
  promos: [],
};

export default function newsReducr(state = initialState, action) {
  switch (action.type) {
    case SET_PROMOS_LOADING:
    case SET_NEWS_LOADING: {
      state = {
        ...state,
        isLoading: action.payload,
      };
      break;
    }
    case SET_NEWS: {
      state = {
        ...state,
        items: action.payload,
        isLoading: false,
      };
      break;
    }
    case SET_PROMOS: {
      state = {
        ...state,
        promos: action.payload,
        isLoading: false,
      };
      break;
    }
    default:
      return state;
  }
  return state;
}
