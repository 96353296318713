import React, { useState, useEffect } from "react";

import axios from "axios";
import { GlobalContext } from "context/GlobalContext";
import moment from "moment";

import numberFormat from "helper/numberFormat";

const currencyOptions = {
  option1: "$",
  option2: "₾",
};

export const GlobalProvider = ({ children }) => {
  const [currency, setCurrency] = useState(currencyOptions.option2);

  const [currencyRate, setCurrencyRate] = useState(1);

  const getCurrencyRate = async () => {
    const date = moment(new Date()).format("YYYY-MM-DD");
    
    const rateData = await axios.get(
      `https://nbg.gov.ge/gw/api/ct/monetarypolicy/currencies/?date=${date}`
    );
    const rate = rateData?.data[0].currencies.find(
      (currency) => currency?.code === "USD"
    )?.rate;
    if (rate) setCurrencyRate(rate);
  };

  useEffect(() => {
    getCurrencyRate();
  }, []);

  const onCurrencyChange = (currency) => {
    setCurrency(currency);
    localStorage.setItem("currency", currency);
  };

  useEffect(() => {
    if (localStorage.getItem("currency"))
      setCurrency(localStorage.getItem("currency"));
  }, []);

  const getPriceByCurrency = (price) => {
    if (currency === currencyOptions.option2) {
      return numberFormat(Math.ceil(Number(price) * currencyRate));
    } else {
      // return numberFormat(Math.ceil(Number(price) / currencyRate));
      return numberFormat(price );
    }
  };

  const getPriceWithCurrency = (price) => {
    return currency === currencyOptions.option2 ? (
      <>
        {getPriceByCurrency(price)}
        <span className="lari">&#8382;</span>
      </>
    ) : (
      <>
        <span>$</span> {getPriceByCurrency(price)}
      </>
    );
  };

  return (
    <GlobalContext.Provider
      value={{
        setCurrency: onCurrencyChange,
        currency,
        currencyRate,
        getPriceByCurrency,
        getPriceWithCurrency,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
