import { SET_SERVECES_LOADER,SET_SERVICES } from "../const/service.const";


const initialState = {
  isLoading: true,
  items: [],
};

export default function serviceReducr(state = initialState, action) {
  switch (action.type) {
    case SET_SERVECES_LOADER: {
      state = {
        ...state,
        isLoading: action.payload,
      };
      break;
    }
    case SET_SERVICES: {
      state = {
        ...state,
        items: action.payload,
        isLoading: false,
      };
      break;
    }
    default:
      return state;
  }
  return state;
}
