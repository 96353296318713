import React, { useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Trans } from "react-i18next";
import "./Subnav.css";
import { Icon } from "../simple/Icon";

const Subnav = ({ closeSubNav, burgerCloseBtn, className, onMouseEnter }) => {
  return (
    <div
      onMouseLeave={closeSubNav}
      className={"sub-nav " + className}
      onMouseEnter={onMouseEnter}
    >
      {!burgerCloseBtn && (
        <button onClick={closeSubNav} className="back-to-subburger">
          <div className="back-to-burger-icon">
            <Icon
              name="arrow-down"
              width="17.717"
              height="15.386"
              view="0 0 8.717 5.386"
            />
          </div>
          <li>
            <NavLink
              to="/tradein"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <Trans i18nKey="მეორადი ავტომობილები" />
            </NavLink>
          </li>
        </button>
      )}

      <li className="sub-nav-items container">
        <div className="sub-nav-item">
          <NavLink
            className={`sub-nav-link ${(navData) =>
              navData.isActive ? "active" : ""}`}
            to="/tradein"
            onMouseEnter={onMouseEnter}
          >
            <Trans i18nKey="ავტომობილის ყიდვა" />
          </NavLink>
          <NavLink
            className={`sub-nav-link-second ${(navData) =>
              navData.isActive ? "active" : ""}`}
            to="/feedback"
            onMouseEnter={onMouseEnter}
          >
            <Trans i18nKey="ავტომობილის გაყიდვა" />
          </NavLink>
        </div>
        <div className="sub-nav-bg" />
        {/* {burgerCloseBtn && (
          <button className="sub-nav-btn" onClick={closeSubNav}>
            <Icon
              name="close-btn-icon"
              width="12.729"
              height="12.729"
              view="0 0 12.729 12.729"
            />
          </button>
        )} */}
      </li>
    </div>
  );
};

export default Subnav;
