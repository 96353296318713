import { SET_SLIDER_LOADING, START_FETCH_SLIDER, SET_SLIDER } from "../const/slider.const";

export const startFetchSliders = ()=>({
    type:START_FETCH_SLIDER
})
export const setSliderLoading = (payload)=>({
    type:SET_SLIDER_LOADING,
    payload
})
export const setSlaiders = (payload)=>({
    type:SET_SLIDER,
    payload
})