import fetch from './fetch';

export const fetchAllCars = ({
  page = 0,
  mileage1 = '',
  mileage2 = '',
  price1 = '',
  price2 = '',
  year1 = '',
  year2 = '',
  modelid = '',
}) => {
  return fetch().get(
    `/usedcars/filter?page=${page}&mileage1=${mileage1}&mileage2=${mileage2}&price1=${price1}&price2=${price2}&year1=${year1}&year2=${year2}&modelid=${modelid}`
  )
}

export const fetchModelById = (id) => fetch().get(`/usedcars/get/${id}`);

export const fetchModelBySlug = (model) => fetch().get(`/car/get/${model}`);

export const fetchHomeNewModels = () => fetch().get(`/home/getcars`);

export const fetchCarPhotosByColor = (carid, colorid) => fetch().get(`/car/photo360/findbycolor/${carid}/${colorid}`);

export const fetchCarPhotosByColorDetail = (carid, colorid) =>
  fetch().get(`/car/photo360/findbycolordetail/${carid}/${colorid}`);

export const fetchModelsCars = (key = 'home') => fetch().get(`/home/getcars?key=${key}`);

export const fetchCarsModelsList = () => fetch().get(`/home/getcarsize`);

export const fetchFilterCarsByModel = (model) => fetch().get(`/home/getcarsbymodel?model=${model}`);

export const fetchSearchModel = (search) => fetch().get(`/home/search?search=${search}`);

export const fetchComplect = (complectId) => fetch().get(`/car/complect/getcomplect/${complectId}`);

export const fetchEngines = (carid) => fetch().get(`/car/complect/getallengine/${carid}`);

export const fetchCalculatorFirst = (fuelConsumption, fuelCost) =>
  fetch().get(`/car/calculator/first?fuelConsumption=${fuelConsumption}&fuelCost=${fuelCost}`);

export const fetchCalculatorSecond = (watCost) => fetch().get(`/car/calculator/second?watCost=${watCost}`);

export const fetchPrams = (complectid, colorid, tireid, featureList) =>
  fetch().get(`/car/complect/getaftercomplect/${complectid}/${colorid}/${tireid}?featureList=${featureList}`);

export const fetchUsedCarsModels = () => fetch().get(`/usedcars/model/findall`);

export const fetchGeneratePdf = ({ carInvoice, carid, colorid, complectid, featureList, tireid }) => {
  return fetch().post(
    `/forms/invoice?carid=${carid}&colorid=${colorid}&complectid=${complectid}&featureList=${featureList}&tireid=${tireid}`,
    carInvoice
  );
};

export const fetchSaveTestDrive = ({ carId, engineId, email, fullName, number }) =>
  fetch().post(`/forms/testdrive/${carId}`, { engineId, email, fullName, number });
export const fetchSaveCallAgent = ({ email, fullName, localdatetime, location, phone }) =>
  fetch().post(
    `/services/agent?fullName=${fullName}&email=${email}&localdatetime=${localdatetime}&location=${location}&phone=${phone}`
  );

/**
 * fetch model compare data
 * @param {*} id 
 * @returns 
 */
export const fetchModelCompare = (carId) => fetch().get(`/complect/compare/${carId}`);