import Footer from "components/common/Footer";
import HHeader from "components/common/HHeader";
import ZohoChat from "components/common/ZohoChat";
import { ScrollTop } from "components/common/simple/ScrollTop";
import Svgicons from "components/common/simple/Svgicons";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const Layout = ({ t, i18n }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "PageView");
    }
  }, []);

  return (
    <ScrollTop key={`${i18n.language}`}>
      <ZohoChat />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Svgicons />
      <HHeader key={`current-${pathname}`} />
      <main>
        <Outlet />
      </main>
      <Footer />
    </ScrollTop>
  );
};

export default Layout;
