import React, { useMemo } from 'react'

export const Icon = props =>
  useMemo(() => {
    let addProps = {}
    if (props.onClick) addProps.onClick = props.onClick
    if(props.width) addProps.width = props.width
    if(props.height) addProps.height = props.height
    return (
      <svg
        viewBox={props.view}
        xmlns="http://www.w3.org/2000/svg"
        {...addProps}
      >
        <use xlinkHref={`#${props.name}`} href={`#${props.name}`} />
      </svg>
    )
  }, [props])


