import React from 'react';

export default function Svgicons() {
  return (
    <svg style={{ width: 0, height: 0, position: 'absolute', zIndex: -1 }} xmlns='http://www.w3.org/2000/svg'>
      <symbol id='language' width='16' height='16' viewBox='0 0 16 16'>
        <g id='Group_342' data-name='Group 342' transform='translate(-1674 -83)'>
          <g
            id='Ellipse_6'
            data-name='Ellipse 6'
            transform='translate(1674 83)'
            fill='none'
            stroke='#666'
            strokeWidth='1.25'
          >
            <circle cx='8' cy='8' r='8' stroke='none' />
            <circle cx='8' cy='8' r='7.375' fill='none' />
          </g>
          <path
            id='_38'
            data-name='38'
            d='M1563.709,563.987c-.855.04-1.227.635-1.669,2.756a2.563,2.563,0,0,0,.733,2.193c.884,1.03,1.185.35,1.9.739a2.784,2.784,0,0,1,1.125,3.152c-.218,1.082.572,2.53,1.789,2.334'
            transform='translate(119.207 -480.173)'
            fill='none'
            stroke='#666'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.25'
          />
          <path
            id='_38_copy'
            data-name='38 copy'
            d='M1558.489,577.665c.261-3.451,2.7-3.2,2.741-5.97.019-1.085-1.513-4.243-4.111-3.582'
            transform='translate(119 -481)'
            fill='none'
            stroke='#666'
            strokeWidth='1.25'
          />
        </g>
      </symbol>
      <symbol id='star-icon' height='407pt' width='407pt' xmlns='http://www.w3.org/2000/svg'>
        <path
          id='Path_314'
          data-name='Path 314'
          d='M15.432,4.823H9.645L7.716,0,5.787,4.823H0L3.858,9.645,1.929,15.432l5.787-3.376L13.5,15.432,11.574,9.645Z'
        />
      </symbol>
      <symbol
        id='phone-icon'
        data-name='Group 32'
        xmlns='http://www.w3.org/2000/svg'
        width='14.968'
        height='15'
        viewBox='0 0 14.968 15'
      >
        <path
          id='Path_223'
          data-name='Path 223'
          d='M15.115,11.008,13.022,8.915a1.392,1.392,0,0,0-2.318.523,1.425,1.425,0,0,1-1.645.9A6.455,6.455,0,0,1,5.172,6.448a1.355,1.355,0,0,1,.9-1.645,1.392,1.392,0,0,0,.523-2.318L4.5.393a1.493,1.493,0,0,0-2.019,0L1.06,1.813c-1.42,1.5.15,5.458,3.663,8.971s7.476,5.159,8.971,3.663l1.42-1.42A1.493,1.493,0,0,0,15.115,11.008Z'
          transform='translate(-0.539 0)'
          fill='#666'
        />
      </symbol>
      <symbol id='facebook-icon' xmlns='http://www.w3.org/2000/svg' width='6' height='12' viewBox='0 0 6 12'>
        <path
          id='iconfinder_icon-social-facebook_211902'
          d='M164,68V66.809c0-.537.119-.809.953-.809H166V64h-1.747c-2.141,0-2.847.981-2.847,2.666V68H160v2h1.406v6H164V70h1.762L166,68Z'
          transform='translate(-160 -64)'
          fill='#666'
        />
      </symbol>
      <symbol
        id='instagram-icon'
        xmlns='http://www.w3.org/2000/svg'
        width='13.997'
        height='13.994'
        viewBox='0 0 13.997 13.994'
      >
        <g id='comp_x5F_173-instagram' transform='translate(-26.001 -26.053)'>
          <g id='Group_3652' data-name='Group 3652' transform='translate(26.001 26.053)'>
            <path
              id='Path_1034'
              data-name='Path 1034'
              d='M33,29.462a3.588,3.588,0,1,0,3.588,3.588A3.582,3.582,0,0,0,33,29.462Zm0,5.921a2.333,2.333,0,1,1,2.333-2.333A2.337,2.337,0,0,1,33,35.382Zm4.572-6.067a.837.837,0,1,1-.837-.837A.835.835,0,0,1,37.572,29.315Zm2.376.849A3.876,3.876,0,0,0,35.886,26.1c-1.155-.066-4.618-.066-5.774,0a3.871,3.871,0,0,0-4.063,4.059c-.066,1.155-.066,4.618,0,5.774A3.876,3.876,0,0,0,30.112,40c1.155.066,4.618.066,5.774,0a3.876,3.876,0,0,0,4.062-4.063C40.014,34.78,40.014,31.32,39.949,30.164Zm-1.493,7.01a2.362,2.362,0,0,1-1.33,1.33A15.42,15.42,0,0,1,33,38.786a15.542,15.542,0,0,1-4.125-.281,2.361,2.361,0,0,1-1.33-1.33,15.425,15.425,0,0,1-.281-4.125,15.544,15.544,0,0,1,.281-4.125,2.362,2.362,0,0,1,1.33-1.33A15.422,15.422,0,0,1,33,27.314a15.543,15.543,0,0,1,4.125.281,2.361,2.361,0,0,1,1.33,1.33,15.422,15.422,0,0,1,.281,4.125A15.413,15.413,0,0,1,38.456,37.175Z'
              transform='translate(-26.001 -26.053)'
              fill='#666'
            />
          </g>
        </g>
      </symbol>
      <symbol id='youtube-icon' width='15' height='10.55' viewBox='0 0 15 10.55'>
        <g id='black' transform='translate(-10.826 -16.943)'>
          <g id='youtube' transform='translate(10.826 16.943)'>
            <path
              id='Fill-195'
              d='M15.676,3.219a3.246,3.246,0,0,0-.6-1.489,2.145,2.145,0,0,0-1.5-.635C11.478.943,8.329.943,8.329.943H8.322s-3.148,0-5.248.152a2.146,2.146,0,0,0-1.5.635,3.244,3.244,0,0,0-.6,1.489,22.686,22.686,0,0,0-.15,2.428V6.785a22.685,22.685,0,0,0,.15,2.427,3.244,3.244,0,0,0,.6,1.489,2.543,2.543,0,0,0,1.654.641c1.2.115,5.1.151,5.1.151s3.152,0,5.251-.156a2.145,2.145,0,0,0,1.5-.635,3.246,3.246,0,0,0,.6-1.489,22.735,22.735,0,0,0,.15-2.427V5.647A22.736,22.736,0,0,0,15.676,3.219Zm-8.9,4.945V3.949L10.83,6.064Z'
              transform='translate(-0.826 -0.943)'
              fill='#666'
              fillRule='evenodd'
            />
          </g>
        </g>
      </symbol>
      <symbol id='arrow-down' xmlns='http://www.w3.org/2000/svg' width='8.717' height='5.386' viewBox='0 0 8.717 5.386'>
        <path
          id='Path_296'
          data-name='Path 296'
          d='M13.028,21.386l3.331-3.331,3.331,3.331,1.028-1.028L16.358,16,12,20.358Z'
          transform='translate(20.717 21.386) rotate(180)'
          fill='#666'
        />
      </symbol>
      <symbol id='burger-menu' xmlns='http://www.w3.org/2000/svg' width='24' height='20' viewBox='0 0 24 20'>
        <g id='Group_3044' data-name='Group 3044' transform='translate(-335 -110)'>
          <rect
            id='Rectangle_1523'
            data-name='Rectangle 1523'
            width='24'
            height='4'
            transform='translate(335 110)'
            fill='#032b5f'
          />
          <rect
            id='Rectangle_1524'
            data-name='Rectangle 1524'
            width='24'
            height='4'
            transform='translate(335 118)'
            fill='#032b5f'
          />
          <rect
            id='Rectangle_1525'
            data-name='Rectangle 1525'
            width='24'
            height='4'
            transform='translate(335 126)'
            fill='#032b5f'
          />
        </g>
      </symbol>
      <symbol
        id='close-btn-icon'
        xmlns='http://www.w3.org/2000/svg'
        width='12.729'
        height='12.729'
        viewBox='0 0 12.729 12.729'
      >
        <g id='Group_3170' data-name='Group 3170' transform='translate(0)'>
          <rect
            id='Rectangle_1573'
            data-name='Rectangle 1573'
            width='16'
            height='2'
            transform='translate(12.729 1.415) rotate(135)'
            fill='#032b5f'
          />
          <rect
            id='Rectangle_1574'
            data-name='Rectangle 1574'
            width='16'
            height='2'
            transform='translate(1.414) rotate(45)'
            fill='#032b5f'
          />
        </g>
      </symbol>
      <symbol
        id='search-icon'
        xmlns='http://www.w3.org/2000/svg'
        width='15.864'
        height='15.9'
        viewBox='0 0 15.864 15.9'
      >
        <g id='Group_3663' data-name='Group 3663' transform='translate(-1494 -563)'>
          <g
            id='Ellipse_1'
            data-name='Ellipse 1'
            transform='translate(1494 563)'
            fill='none'
            stroke='#000'
            strokeWidth='1.25'
          >
            <circle cx='5.99' cy='5.99' r='5.99' stroke='none' />
            <circle cx='5.99' cy='5.99' r='5.365' fill='none' />
          </g>
          <line
            id='Rectangle_4'
            data-name='Rectangle 4'
            x2='5.86'
            y2='5.9'
            transform='translate(1503.561 572.56)'
            fill='none'
            stroke='#000'
            strokeWidth='1.25'
          />
        </g>
      </symbol>

      <symbol
        id='serchice-car-settings-icon'
        data-name='Group 3666'
        xmlns='http://www.w3.org/2000/svg'
        width='63.753'
        height='61.822'
        viewBox='0 0 63.753 61.822'
      >
        <g id='Group_357' data-name='Group 357' transform='translate(1.932)'>
          <g id='Group_350' data-name='Group 350' transform='translate(0 11.592)'>
            <g id='Group_349' data-name='Group 349'>
              <g id='Group_343' data-name='Group 343' transform='translate(3.863)'>
                <path
                  id='Path_297'
                  data-name='Path 297'
                  d='M1568.932,4637.185a1.976,1.976,0,0,1-.549-.081,1.933,1.933,0,0,1-1.3-2.4l5.185-17.485a4.806,4.806,0,0,1,4.4-3.217h11.592a1.932,1.932,0,0,1,0,3.864H1576.66a.985.985,0,0,0-.709.512l-5.166,17.424A1.934,1.934,0,0,1,1568.932,4637.185Z'
                  transform='translate(-1567 -4614)'
                />
              </g>
              <g id='Group_344' data-name='Group 344' transform='translate(3.864 42.502)'>
                <path
                  id='Path_298'
                  data-name='Path 298'
                  d='M1580.524,4643.729h-11.592A1.932,1.932,0,0,1,1567,4641.8v-3.864a1.932,1.932,0,0,1,3.864,0v1.933h7.728v-1.933a1.932,1.932,0,0,1,3.864,0v3.864A1.932,1.932,0,0,1,1580.524,4643.729Z'
                  transform='translate(-1567 -4636)'
                />
              </g>
              <g id='Group_345' data-name='Group 345' transform='translate(0 21.251)'>
                <path
                  id='Path_299'
                  data-name='Path 299'
                  d='M1597.842,4648.183h-28.979a3.867,3.867,0,0,1-3.864-3.863v-15.457a3.867,3.867,0,0,1,3.864-3.863h27.047a1.932,1.932,0,1,1,0,3.863h-27.047v15.457h28.979a1.932,1.932,0,1,1,0,3.863Z'
                  transform='translate(-1565 -4625)'
                />
              </g>
              <g id='Group_346' data-name='Group 346' transform='translate(3.864 42.502)'>
                <path
                  id='Path_300'
                  data-name='Path 300'
                  d='M1580.524,4643.729h-11.592A1.932,1.932,0,0,1,1567,4641.8v-3.864a1.932,1.932,0,0,1,3.864,0v1.933h7.728v-1.933a1.932,1.932,0,0,1,3.864,0v3.864A1.932,1.932,0,0,1,1580.524,4643.729Z'
                  transform='translate(-1567 -4636)'
                />
              </g>
              <g id='Group_348' data-name='Group 348' transform='translate(7.941 29.309)'>
                <g id='Group_347' data-name='Group 347' transform='translate(0 0)'>
                  <circle
                    id='Ellipse_50'
                    data-name='Ellipse 50'
                    cx='3.5'
                    cy='3.5'
                    r='3.5'
                    transform='translate(0.127 0.254)'
                  />
                </g>
              </g>
            </g>
          </g>
          <g id='Group_356' data-name='Group 356' transform='translate(30.911)'>
            <g id='Group_354' data-name='Group 354' transform='translate(7.727 24.02)'>
              <g id='Group_351' data-name='Group 351' transform='translate(9.754 0.016)'>
                <path
                  id='Path_301'
                  data-name='Path 301'
                  d='M1593.816,4651.307a1.932,1.932,0,0,1-1.926-1.8l-1.837-27a1.932,1.932,0,0,1,3.856-.261l1.838,27a1.936,1.936,0,0,1-1.8,2.06Z'
                  transform='translate(-1590.049 -4620.441)'
                />
              </g>
              <g id='Group_352' data-name='Group 352'>
                <path
                  id='Path_302'
                  data-name='Path 302'
                  d='M1586.934,4651.314c-.044,0-.089,0-.131,0a1.934,1.934,0,0,1-1.8-2.059l1.837-27.017a1.932,1.932,0,0,1,3.856.262l-1.837,27.019A1.933,1.933,0,0,1,1586.934,4651.314Z'
                  transform='translate(-1585 -4620.433)'
                />
              </g>
              <g id='Group_353' data-name='Group 353' transform='translate(0.001 27.018)'>
                <path
                  id='Path_303'
                  data-name='Path 303'
                  d='M1592.728,4644.078a7.735,7.735,0,0,1-7.728-7.727,1.932,1.932,0,1,1,3.864,0,3.864,3.864,0,1,0,7.727,0,1.932,1.932,0,1,1,3.864,0A7.735,7.735,0,0,1,1592.728,4644.078Z'
                  transform='translate(-1585 -4634.418)'
                />
              </g>
            </g>
            <g id='Group_355' data-name='Group 355'>
              <path
                id='Path_304'
                data-name='Path 304'
                d='M1600.414,4635.9a1.933,1.933,0,0,1-.564-3.781,11.589,11.589,0,0,0,6.263-17.491l0,3.84a1.938,1.938,0,0,1-.918,1.645l-5.345,3.306a7.359,7.359,0,0,1-6.646.083l-5.495-3.388a1.941,1.941,0,0,1-.916-1.645v-3.838a11.591,11.591,0,0,0,6.211,17.474,1.932,1.932,0,1,1-1.147,3.689,15.46,15.46,0,0,1-4.241-27.442,1.931,1.931,0,0,1,3.037,1.584l0,7.455,4.428,2.74a3.368,3.368,0,0,0,2.883-.083l4.281-2.655,0-7.457a1.932,1.932,0,0,1,3.037-1.586,15.456,15.456,0,0,1-4.306,27.466A1.9,1.9,0,0,1,1600.414,4635.9Z'
                transform='translate(-1581 -4608)'
              />
            </g>
          </g>
        </g>
        <g id='Group_358' data-name='Group 358' transform='translate(0 27.047)'>
          <path
            id='Path_305'
            data-name='Path 305'
            d='M1571.728,4625.864h-5.8a1.932,1.932,0,1,1,0-3.864h5.8a1.932,1.932,0,1,1,0,3.864Z'
            transform='translate(-1564 -4622)'
          />
        </g>
      </symbol>

      <symbol id='order-service-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50.755 58.186'>
        <g id='Group_3882' data-name='Group 3882' transform='translate(-964 -996)'>
          <path
            id='Path_1099'
            data-name='Path 1099'
            d='M1029.382,1101.72l-1.856-11.018a2.928,2.928,0,0,0-2.9-2.449h-22.5a2.928,2.928,0,0,0-2.9,2.449l-1.856,11.018a5.2,5.2,0,0,0-5.076,5.187v16.906a2.94,2.94,0,0,0,2.936,2.937h4.748a2.94,2.94,0,0,0,2.937-2.937V1122h20.913v1.813a2.94,2.94,0,0,0,2.937,2.937h4.748a2.94,2.94,0,0,0,2.937-2.937v-16.906A5.2,5.2,0,0,0,1029.382,1101.72Zm-26.942-10.165h21.881l1.712,10.162h-25.305Zm28.716,31.894h-4.017V1118.7H999.623v4.749h-4.017v-16.541a1.891,1.891,0,0,1,1.889-1.889h31.774a1.891,1.891,0,0,1,1.888,1.889Z'
            transform='translate(-22.264 -72.565)'
            fill='#231916'
          />
          <path
            id='Path_1100'
            data-name='Path 1100'
            d='M1022.028,1175.9a4.9,4.9,0,1,0,4.9,4.9A4.906,4.906,0,0,0,1022.028,1175.9Zm0,6.869A1.968,1.968,0,1,1,1024,1180.8,1.971,1.971,0,0,1,1022.028,1182.768Z'
            transform='translate(-41.789 -141.505)'
            fill='#231916'
          />
          <path
            id='Path_1101'
            data-name='Path 1101'
            d='M1123.977,1175.9a4.9,4.9,0,1,0,4.9,4.9A4.906,4.906,0,0,0,1123.977,1175.9Zm0,6.869a1.968,1.968,0,1,1,1.968-1.968A1.971,1.971,0,0,1,1123.977,1182.768Z'
            transform='translate(-121.98 -141.505)'
            fill='#231916'
          />
          <path
            id='Path_1102'
            data-name='Path 1102'
            d='M972.582,996a9.115,9.115,0,0,0-8.487,5.869,1.475,1.475,0,0,0,1.38,1.995h6.036v2.41h-6.036a1.475,1.475,0,0,0-1.38,1.995,9.07,9.07,0,0,0,16.375,1.276h34.286v-8.951H980.47A9.072,9.072,0,0,0,972.582,996Zm39.35,10.72H978.676l-.37.846a6.242,6.242,0,0,1-10.492,1.531h3.877a2.646,2.646,0,0,0,2.643-2.643v-2.771a2.646,2.646,0,0,0-2.643-2.643h-3.877a6.242,6.242,0,0,1,10.492,1.531l.37.846h33.255Z'
            fill='#231916'
          />
        </g>
      </symbol>

      <symbol id='order-testdrive-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 62.307 56.666'>
        <g id='Group_3881' data-name='Group 3881' transform='translate(-1479.644 -1047.691)'>
          <path
            id='Path_1095'
            data-name='Path 1095'
            d='M1696.8,1145.941a5.293,5.293,0,1,0-5.293,5.293A5.3,5.3,0,0,0,1696.8,1145.941Zm-7.588,0a2.295,2.295,0,1,1,2.294,2.295A2.3,2.3,0,0,1,1689.217,1145.941Z'
            transform='translate(-160.69 -72.309)'
            fill='#231916'
          />
          <rect
            id='Rectangle_2024'
            data-name='Rectangle 2024'
            width='3.376'
            height='21.894'
            transform='translate(1494.158 1082.463)'
            fill='#231916'
          />
          <path
            id='Path_1096'
            data-name='Path 1096'
            d='M1486.262,1102.9a1.883,1.883,0,0,1,1.855-1.553h7.289a1.872,1.872,0,0,1,1.752,1.193l3.132,7.954h11.3l12.85-12.85-2.387-2.387-11.861,11.861h-7.6l-2.29-5.815a5.229,5.229,0,0,0-4.893-3.332h-7.289a5.256,5.256,0,0,0-5.179,4.335l-3.294,18.431,3.323.594Z'
            transform='translate(0 -37.002)'
            fill='#231916'
          />
          <rect
            id='Rectangle_2025'
            data-name='Rectangle 2025'
            width='3.376'
            height='21.894'
            transform='translate(1486.518 1082.463)'
            fill='#231916'
          />
          <path
            id='Path_1097'
            data-name='Path 1097'
            d='M1509.647,1053.409a5.718,5.718,0,1,0,5.718-5.718A5.724,5.724,0,0,0,1509.647,1053.409Zm8.06,0a2.342,2.342,0,1,1-2.342-2.342A2.343,2.343,0,0,1,1517.707,1053.409Z'
            transform='translate(-23.339)'
            fill='#231916'
          />
          <path
            id='Path_1098'
            data-name='Path 1098'
            d='M1612.946,1062.564l-2.069-12.281a3.1,3.1,0,0,0-3.064-2.592h-24.855a3.1,3.1,0,0,0-3.064,2.592l-1.8,10.69h3.424l1.669-9.906h24.4l1.937,11.5h-5.644l-3.376,3.376h12.433a2.224,2.224,0,0,1,2.221,2.222v18.408h-4.709v-5.246h-26.733v3.376h23.357v2.138a3.112,3.112,0,0,0,3.108,3.108h5.245a3.112,3.112,0,0,0,3.108-3.108v-18.676A5.6,5.6,0,0,0,1612.946,1062.564Z'
            transform='translate(-76.582)'
            fill='#231916'
          />
        </g>
      </symbol>

      <symbol id='create-hyundai-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52.879 48.198'>
        <g id='Group_3880' data-name='Group 3880' transform='translate(-159.641 -668.433)'>
          <path
            id='Path_1092'
            data-name='Path 1092'
            d='M162.862,716.631h4.494a3.225,3.225,0,0,0,3.221-3.221v-2.857h31.007v2.857a3.225,3.225,0,0,0,3.221,3.221H209.3a3.225,3.225,0,0,0,3.221-3.221V691.771a6.112,6.112,0,0,0-6.105-6.105h-.309l-2.451-14.547a3.212,3.212,0,0,0-3.176-2.686h-28.8a3.212,3.212,0,0,0-3.176,2.686l-2.45,14.547h-.309a6.112,6.112,0,0,0-6.105,6.105V713.41A3.225,3.225,0,0,0,162.862,716.631Zm4.565-3.221a.07.07,0,0,1-.07.07h-4.494a.07.07,0,0,1-.07-.07v-2.857h4.635Zm41.943,0a.07.07,0,0,1-.07.07H204.8a.07.07,0,0,1-.07-.07v-2.857h4.635Zm-37.756-41.768a.07.07,0,0,1,.069-.059h28.8a.07.07,0,0,1,.069.059l2.362,14.023h-33.66Zm-8.821,20.129a2.958,2.958,0,0,1,2.955-2.955h40.668a2.958,2.958,0,0,1,2.955,2.955V707.4H162.792Z'
            fill='#231916'
          />
          <path
            id='Path_1093'
            data-name='Path 1093'
            d='M196.473,794.956a5.9,5.9,0,1,0-5.9-5.9A5.9,5.9,0,0,0,196.473,794.956Zm0-8.785a2.89,2.89,0,1,1-2.89,2.89A2.893,2.893,0,0,1,196.473,786.172Z'
            transform='translate(-24.525 -90.952)'
            fill='#231916'
          />
          <path
            id='Path_1094'
            data-name='Path 1094'
            d='M332.834,794.956a5.9,5.9,0,1,0-5.9-5.9A5.9,5.9,0,0,0,332.834,794.956Zm0-8.785a2.89,2.89,0,1,1-2.89,2.89A2.893,2.893,0,0,1,332.834,786.172Z'
            transform='translate(-132.622 -90.952)'
            fill='#231916'
          />
        </g>
      </symbol>

      <symbol id='engine-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.889 15.723'>
        <g id='XMLID_940_' transform='translate(0.25 -88.739)'>
          <path
            id='XMLID_966_'
            d='M19.158,91.43l-.025,2.747H18.107l-2.426-2.741h-3.3v-1.07h3.212V88.989h-7.8v1.376h3.212v1.07H7.483L5.9,93.295H2.68v3.727h-1.3V94.336H0v6.852H1.376V98.4h1.3v3.911H5.916l1.628,1.723,8.133,0,2.448-2.719h1.008l.025,2.9h4.231V91.465Zm2.854,11.4h-1.49l-.025-2.9H17.512l-2.448,2.719-6.927,0L6.51,100.933H4.056V94.671H6.53l1.587-1.859h6.943l2.426,2.741H20.5l.025-2.736,1.49.012Z'
            stroke='#000'
            strokeWidth='0.5'
          />
        </g>
      </symbol>

      <symbol id='speed-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <path id='Path_1074' data-name='Path 1074' d='M0,0H24V24H0Z' fill='none' />
        <path
          id='Path_1075'
          data-name='Path 1075'
          d='M20.38,8.57l-1.23,1.85A8,8,0,0,1,18.93,18H5.07A8,8,0,0,1,15.58,6.85l1.85-1.23A10,10,0,0,0,3.35,19a2,2,0,0,0,1.72,1H18.92a2,2,0,0,0,1.74-1,10,10,0,0,0-.27-10.44Z'
        />
        <path
          id='Path_1076'
          data-name='Path 1076'
          d='M10.59,15.41a2,2,0,0,0,2.83,0l5.66-8.49-8.49,5.66a2,2,0,0,0,0,2.83Z'
        />
      </symbol>

      <symbol id='shield-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <path id='Path_1077' data-name='Path 1077' d='M0,0H24V24H0Z' fill='none' />
        <path
          id='Path_1078'
          data-name='Path 1078'
          d='M12,1,3,5v6c0,5.55,3.84,10.74,9,12,5.16-1.26,9-6.45,9-12V5Zm0,10.99h7c-.53,4.12-3.28,7.79-7,8.94V12H5V6.3l7-3.11v8.8Z'
        />
      </symbol>

      <symbol id='fuel-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <path id='Path_1072' data-name='Path 1072' d='M0,0H24V24H0Z' fill='none' />
        <path
          id='Path_1073'
          data-name='Path 1073'
          d='M19.77,7.23l.01-.01L16.06,3.5,15,4.56l2.11,2.11A2.5,2.5,0,0,0,18,11.5a2.556,2.556,0,0,0,1-.21V18.5a1,1,0,0,1-2,0V14a2.006,2.006,0,0,0-2-2H14V5a2.006,2.006,0,0,0-2-2H6A2.006,2.006,0,0,0,4,5V21H14V13.5h1.5v5a2.5,2.5,0,0,0,5,0V9A2.5,2.5,0,0,0,19.77,7.23ZM12,13.5V19H6V12h6ZM12,10H6V5h6Zm6,0a1,1,0,1,1,1-1A1,1,0,0,1,18,10Z'
        />
      </symbol>

      <symbol id='play-btn-icon1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 168.837 168.836'>
        <g id='play_circle_outline-24px_2_' data-name='play_circle_outline-24px (2)' transform='translate(-0.001)'>
          <path
            id='Path_1131'
            data-name='Path 1131'
            d='M0,0H168.837V168.837H0Z'
            transform='translate(0.001)'
            fill='none'
          />
          <path
            id='Path_1132'
            data-name='Path 1132'
            d='M58.279,104.005l42.209-31.657L58.279,40.692ZM72.349,2A70.349,70.349,0,1,0,142.7,72.349,70.374,70.374,0,0,0,72.349,2Zm0,126.627a56.279,56.279,0,1,1,56.279-56.279A56.354,56.354,0,0,1,72.349,128.627Z'
            transform='translate(12.07 12.07)'
            fill='#fff'
          />
        </g>
      </symbol>

      <symbol
        id='serchice-key-icon'
        xmlns='http://www.w3.org/2000/svg'
        width='56.18'
        height='56.179'
        viewBox='0 0 56.18 56.179'
      >
        <g id='Group_375' data-name='Group 375' transform='translate(21.068)'>
          <g id='Group_373' data-name='Group 373'>
            <path
              id='Path_328'
              data-name='Path 328'
              d='M1758.323,4725.112c-4.017,0-7.449-2.492-10.556-5.439a1.906,1.906,0,0,1-.2-.172c-.16-.161-.316-.318-.474-.48l-.479-.474a1.563,1.563,0,0,1-.17-.2c-2.949-3.107-5.443-6.539-5.443-10.557,0-4.83,11.1-17.79,16.459-17.79,5.161,0,9.008,3.85,11.817,6.662,2.721,2.718,6.837,6.83,6.837,11.992C1776.113,4714.01,1763.153,4725.112,1758.323,4725.112Zm-8.748-8.574c2.633,2.578,5.685,5.063,8.748,5.063,2.983,0,14.279-9.619,14.279-12.947,0-3.706-3.357-7.06-5.809-9.507-2.507-2.513-5.627-5.636-9.333-5.636-3.329,0-12.948,11.294-12.948,14.278C1744.511,4710.852,1747,4713.905,1749.575,4716.538Z'
              transform='translate(-1741 -4690)'
            />
          </g>
          <g id='Group_374' data-name='Group 374' transform='translate(15.799 8.776)'>
            <path
              id='Path_329'
              data-name='Path 329'
              d='M1758.777,4705.535a1.759,1.759,0,0,1-1.708-1.352,7.19,7.19,0,0,0-5.715-5.718,1.755,1.755,0,1,1,.8-3.417,10.729,10.729,0,0,1,8.331,8.329,1.756,1.756,0,0,1-1.308,2.111A1.662,1.662,0,0,1,1758.777,4705.535Z'
              transform='translate(-1749.999 -4695)'
            />
          </g>
        </g>
        <g id='Group_379' data-name='Group 379' transform='translate(0 23.022)'>
          <g id='Group_376' data-name='Group 376'>
            <path
              id='Path_330'
              data-name='Path 330'
              d='M1736.962,4736.271h-6.206a1.755,1.755,0,0,1-1.756-1.756v-6.206a1.751,1.751,0,0,1,.514-1.241l23.441-23.442a1.756,1.756,0,0,1,2.483,2.483l-22.927,22.927v3.723h3.724l2.939-2.938a1.755,1.755,0,0,1,2.482,2.483l-3.453,3.452A1.743,1.743,0,0,1,1736.962,4736.271Z'
              transform='translate(-1729 -4703.114)'
            />
          </g>
          <g id='Group_377' data-name='Group 377' transform='translate(14.103 6.206)'>
            <path
              id='Path_331'
              data-name='Path 331'
              d='M1738.789,4725.7a1.755,1.755,0,0,1-1.241-3l15.544-15.544a1.756,1.756,0,0,1,2.482,2.484l-15.544,15.542A1.75,1.75,0,0,1,1738.789,4725.7Z'
              transform='translate(-1737.033 -4706.649)'
            />
          </g>
          <g id='Group_378' data-name='Group 378' transform='translate(7.022 19.112)'>
            <path
              id='Path_332'
              data-name='Path 332'
              d='M1737.393,4724.593a1.743,1.743,0,0,1-1.241-.515l-2.637-2.638a1.754,1.754,0,0,1,0-2.482l4.443-4.443a1.753,1.753,0,0,1,2.483,0l2.637,2.637a1.755,1.755,0,1,1-2.482,2.482l-1.4-1.395-1.961,1.96,1.4,1.4a1.756,1.756,0,0,1-1.241,3Z'
              transform='translate(-1733 -4714)'
            />
          </g>
        </g>
      </symbol>
      <symbol
        id='service-car-icon'
        xmlns='http://www.w3.org/2000/svg'
        width='68.313'
        height='50.229'
        viewBox='0 0 68.313 50.229'
      >
        <g id='Group_3664' data-name='Group 3664' transform='translate(0)'>
          <g id='Group_369' data-name='Group 369' transform='translate(2.009 0)'>
            <g id='Group_368' data-name='Group 368'>
              <g id='Group_360' data-name='Group 360' transform='translate(4.019)'>
                <path
                  id='Path_306'
                  data-name='Path 306'
                  d='M1785.248,4388.1a2.008,2.008,0,0,1-1.9-1.377l-5.393-16.174a1.031,1.031,0,0,0-.739-.532h-36.166a1.032,1.032,0,0,0-.739.532l-5.393,16.174a2.008,2.008,0,1,1-3.809-1.269l5.391-16.179a4.985,4.985,0,0,1,4.551-3.277h36.166a4.986,4.986,0,0,1,4.551,3.277l5.39,16.179a2.01,2.01,0,0,1-1.269,2.543A2.055,2.055,0,0,1,1785.248,4388.1Z'
                  transform='translate(-1731 -4366)'
                  fill='#000000'
                />
              </g>
              <g id='Group_361' data-name='Group 361' transform='translate(4.018 42.193)'>
                <path
                  id='Path_307'
                  data-name='Path 307'
                  d='M1745.065,4395.036h-12.056a2.009,2.009,0,0,1-2.009-2.009v-4.018a2.009,2.009,0,1,1,4.019,0v2.009h8.037v-2.009a2.009,2.009,0,1,1,4.018,0v4.018A2.009,2.009,0,0,1,1745.065,4395.036Z'
                  transform='translate(-1731 -4387)'
                  fill='#000000'
                />
              </g>
              <g id='Group_362' data-name='Group 362' transform='translate(0 20.092)'>
                <path
                  id='Path_308'
                  data-name='Path 308'
                  d='M1789.276,4400.11h-56.257a4.023,4.023,0,0,1-4.018-4.02v-16.073a4.022,4.022,0,0,1,4.018-4.018h56.257a4.022,4.022,0,0,1,4.019,4.018v16.073A4.023,4.023,0,0,1,1789.276,4400.11Zm0-4.02v0Zm-56.257-16.073v16.073h56.251l.006-16.073Z'
                  transform='translate(-1729 -4376)'
                  fill='#000000'
                />
              </g>
              <g id='Group_363' data-name='Group 363' transform='translate(4.018 42.193)'>
                <path
                  id='Path_309'
                  data-name='Path 309'
                  d='M1745.065,4395.036h-12.056a2.009,2.009,0,0,1-2.009-2.009v-4.018a2.009,2.009,0,1,1,4.019,0v2.009h8.037v-2.009a2.009,2.009,0,1,1,4.018,0v4.018A2.009,2.009,0,0,1,1745.065,4395.036Z'
                  transform='translate(-1731 -4387)'
                  fill='#000000'
                />
              </g>
              <g id='Group_364' data-name='Group 364' transform='translate(44.202 42.193)'>
                <path
                  id='Path_310'
                  data-name='Path 310'
                  d='M1765.064,4395.036h-12.055a2.009,2.009,0,0,1-2.009-2.009v-4.018a2.009,2.009,0,1,1,4.019,0v2.009h8.036v-2.009a2.009,2.009,0,1,1,4.019,0v4.018A2.009,2.009,0,0,1,1765.064,4395.036Z'
                  transform='translate(-1751 -4387)'
                  fill='#000000'
                />
              </g>
              <g id='Group_367' data-name='Group 367' transform='translate(9.535 29.309)'>
                <g id='Group_366' data-name='Group 366' transform='translate(0 0)'>
                  <circle
                    id='Ellipse_52'
                    data-name='Ellipse 52'
                    cx='3.5'
                    cy='3.5'
                    r='3.5'
                    transform='translate(0.117 -0.746)'
                    fill='#000000'
                  />
                </g>
                <g id='Group_381' data-name='Group 381' transform='translate(38 -1)'>
                  <circle
                    id='Ellipse_52-2'
                    data-name='Ellipse 52'
                    cx='3.5'
                    cy='3.5'
                    r='3.5'
                    transform='translate(0.117 0.254)'
                    fill='#000000'
                  />
                </g>
              </g>
            </g>
          </g>
          <g id='Group_370' data-name='Group 370' transform='translate(58.267 14.064)'>
            <path
              id='Path_311'
              data-name='Path 311'
              d='M1765.037,4377.019h-6.028a2.009,2.009,0,0,1,0-4.018h6.028a2.009,2.009,0,1,1,0,4.018Z'
              transform='translate(-1757 -4373)'
              fill='#000000'
            />
          </g>
          <g id='Group_371' data-name='Group 371' transform='translate(0 14.064)'>
            <path
              id='Path_312'
              data-name='Path 312'
              d='M1736.037,4377.019h-6.027a2.009,2.009,0,1,1,0-4.018h6.027a2.009,2.009,0,1,1,0,4.018Z'
              transform='translate(-1728 -4373)'
              fill='#000000'
            />
          </g>
        </g>
      </symbol>
      <symbol id='globe-icon' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
        <g id='Core' transform='translate(-296 -296)'>
          <g id='language' transform='translate(296 296)'>
            <path
              id='Shape'
              d='M10,0A10,10,0,1,0,20,10,10.029,10.029,0,0,0,10,0Zm6.9,6H14a15.805,15.805,0,0,0-1.4-3.6A8.049,8.049,0,0,1,16.9,6ZM10,2a13.428,13.428,0,0,1,1.9,4H8.1A14.7,14.7,0,0,1,10,2ZM2.3,12A6.448,6.448,0,0,1,2,10a6.448,6.448,0,0,1,.3-2H5.7a13.4,13.4,0,0,0-.1,2c0,.7.1,1.3.1,2Zm.8,2H6a15.805,15.805,0,0,0,1.4,3.6A8.049,8.049,0,0,1,3.1,14ZM6,6H3.1A8.428,8.428,0,0,1,7.4,2.4,15.805,15.805,0,0,0,6,6Zm4,12a13.428,13.428,0,0,1-1.9-4h3.8A14.7,14.7,0,0,1,10,18Zm2.3-6H7.7a13.552,13.552,0,0,1-.2-2,13.552,13.552,0,0,1,.2-2h4.7a13.552,13.552,0,0,1,.2,2,13.8,13.8,0,0,1-.3,2Zm.3,5.6A14.188,14.188,0,0,0,14,14h2.9a8.049,8.049,0,0,1-4.3,3.6ZM14.4,12a13.4,13.4,0,0,0,.1-2c0-.7-.1-1.3-.1-2h3.4a6.448,6.448,0,0,1,.3,2,6.448,6.448,0,0,1-.3,2Z'
              fill='#fff'
              fillRule='evenodd'
            />
          </g>
        </g>
      </symbol>
      <symbol id='pdf-icon' xmlns='http://www.w3.org/2000/svg' width='37.684' height='20' viewBox='0 0 37.684 20'>
        <g id='iconfinder_tag-pdf_383206' transform='translate(0)'>
          <path
            id='Path_359'
            data-name='Path 359'
            d='M39.864,14H39v5.263h1a2.442,2.442,0,0,0,1.923-.594,2.809,2.809,0,0,0,.672-2.038,2.746,2.746,0,0,0-.672-2A2.614,2.614,0,0,0,39.864,14Z'
            transform='translate(-21.077 -6.632)'
            fill='#032b5f'
          />
          <path
            id='Path_360'
            data-name='Path 360'
            d='M25.354,14.243A2.17,2.17,0,0,0,24.027,14H23v3.158h1.214a1.522,1.522,0,0,0,1.239-.526,1.988,1.988,0,0,0,.327-1.235A1.4,1.4,0,0,0,25.354,14.243Z'
            transform='translate(-12.43 -6.632)'
            fill='#032b5f'
          />
          <path
            id='Path_361'
            data-name='Path 361'
            d='M35.386,0H2.3A2.482,2.482,0,0,0,0,2.632V17.368A2.482,2.482,0,0,0,2.3,20H35.386a2.481,2.481,0,0,0,2.3-2.632V2.632A2.481,2.481,0,0,0,35.386,0ZM14.339,11.312a3.9,3.9,0,0,1-2.695.793H10.57v2.632H8.732V5.263H11.62a3.824,3.824,0,0,1,2.741.894,3.5,3.5,0,0,1,.859,2.594A3.33,3.33,0,0,1,14.339,11.312Zm7.879,2.153a4.437,4.437,0,0,1-3.478,1.272H16.085V5.263h2.749a4.449,4.449,0,0,1,3.355,1.283,4.807,4.807,0,0,1,1.169,3.436A5.062,5.062,0,0,1,22.218,13.465Zm7.653-6.1H26.195V9.474h3.676v1.579H26.195v3.684H24.357V5.263h5.515Z'
            fill='#032b5f'
          />
        </g>
      </symbol>
      <symbol id='play-icon' xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'>
        <g id='Group_3668' data-name='Group 3668' transform='translate(-1006.5 -6424)'>
          <rect
            id='Rectangle_1684'
            data-name='Rectangle 1684'
            width='50'
            height='50'
            rx='25'
            transform='translate(1006.5 6424)'
            fill='#fff'
          />
          <path
            id='Polygon_17'
            data-name='Polygon 17'
            d='M7,0l7,10H0Z'
            transform='translate(1038 6442) rotate(90)'
            fill='#08080a'
          />
        </g>
      </symbol>
      <symbol id='tw-icon' xmlns='http://www.w3.org/2000/svg' width='42' height='42' viewBox='0 0 42 42'>
        <g id='Group_3663' data-name='Group 3663' transform='translate(-238 -1214)'>
          <rect
            id='Rectangle_706'
            data-name='Rectangle 706'
            width='42'
            height='42'
            rx='21'
            transform='translate(238 1214)'
            fill='#55acee'
          />
          <path
            id='Path_2'
            data-name='Path 2'
            d='M43.616,16.385A10.253,10.253,0,0,0,53.961,6.04V5.547a8.009,8.009,0,0,0,1.773-1.872,8.179,8.179,0,0,1-2.069.591A3.833,3.833,0,0,0,55.242,2.3a9.037,9.037,0,0,1-2.266.887A3.519,3.519,0,0,0,50.316,2,3.7,3.7,0,0,0,46.67,5.645a1.921,1.921,0,0,0,.1.788,10.186,10.186,0,0,1-7.488-3.842,3.773,3.773,0,0,0-.493,1.872,3.914,3.914,0,0,0,1.576,3.054,3.321,3.321,0,0,1-1.675-.493h0a3.6,3.6,0,0,0,2.956,3.547,3.037,3.037,0,0,1-.985.1,1.677,1.677,0,0,1-.69-.1,3.733,3.733,0,0,0,3.448,2.562,7.439,7.439,0,0,1-4.532,1.576,2.728,2.728,0,0,1-.887-.1,9.3,9.3,0,0,0,5.616,1.773'
            transform='translate(212.172 1226.062)'
            fill='#fff'
            fillRule='evenodd'
          />
        </g>
      </symbol>
      <symbol id='fb-icon' xmlns='http://www.w3.org/2000/svg' width='42' height='42' viewBox='0 0 42 42'>
        <g id='Group_3664' data-name='Group 3664' transform='translate(-238 -1262)'>
          <rect
            id='Rectangle_625'
            data-name='Rectangle 625'
            width='42'
            height='42'
            rx='21'
            transform='translate(238 1262)'
            fill='#3264ce'
          />
          <g id='iconfinder_197-Facebook_192155' transform='translate(254.615 1275.033)'>
            <path
              id='Path_1004'
              data-name='Path 1004'
              d='M125.8,16.475v-7.4h-2.265V5.749h2.317V2.995s.106-3.469,3.077-3.5h3.308V2.765h-2.052a.887.887,0,0,0-.866.976V5.807h2.968l-.356,3.326h-2.559v7.342Z'
              transform='translate(-123.531 0.501)'
              fill='#fff'
            />
          </g>
        </g>
      </symbol>
      <symbol id='linkedin-icon' xmlns='http://www.w3.org/2000/svg' width='42' height='42' viewBox='0 0 42 42'>
        <g id='Group_3665' data-name='Group 3665' transform='translate(-238 -1309)'>
          <rect
            id='Rectangle_703'
            data-name='Rectangle 703'
            width='42'
            height='42'
            rx='21'
            transform='translate(238 1309)'
            fill='#3b5998'
          />
          <g
            id='iconfinder_038_002_linkedin_social_network_android_material_1646907'
            transform='translate(252.352 1323.595)'
          >
            <rect
              id='Rectangle_113'
              data-name='Rectangle 113'
              width='4'
              height='8'
              transform='translate(-0.351 4.406)'
              fill='#fff'
            />
            <circle
              id='Ellipse_1'
              data-name='Ellipse 1'
              cx='2'
              cy='2'
              r='2'
              transform='translate(-0.351 -0.594)'
              fill='#fff'
            />
            <path
              id='Path_1003'
              data-name='Path 1003'
              d='M14.675,8.25a3.384,3.384,0,0,0-2.648,1.289V8.817H9V17.14h3.027v-5.3a1.513,1.513,0,0,1,3.027,0v5.3H18.08V11.655A3.4,3.4,0,0,0,14.675,8.25Z'
              transform='translate(-4.466 -4.565)'
              fill='#fff'
            />
          </g>
        </g>
      </symbol>
      <symbol id='instagram-icon2' xmlns='http://www.w3.org/2000/svg' width='42' height='42' viewBox='0 0 42 42'>
        <g id='Group_3666' data-name='Group 3666' transform='translate(-238 -1356)'>
          <g
            id='Rectangle_704'
            data-name='Rectangle 704'
            transform='translate(238 1356)'
            fill='none'
            stroke='#707070'
            strokeWidth='1'
            opacity='0.2'
          >
            <rect width='42' height='42' rx='21' stroke='none' />
            <rect x='0.5' y='0.5' width='41' height='41' rx='20.5' fill='none' />
          </g>
          <g id='iconfinder_38-instagram_1161953' transform='translate(249.625 1368.076)'>
            <path
              id='Path_1005'
              data-name='Path 1005'
              d='M20.118,16.7a4.717,4.717,0,1,0,4.718,4.717A4.726,4.726,0,0,0,20.118,16.7Zm0,7.739a3.022,3.022,0,1,1,3.022-3.022A3.036,3.036,0,0,1,20.118,24.439Z'
              transform='translate(-10.977 -12.314)'
            />
            <ellipse
              id='Ellipse_2'
              data-name='Ellipse 2'
              cx='1.069'
              cy='1.069'
              rx='1.069'
              ry='1.069'
              transform='translate(12.973 3.206)'
            />
            <path
              id='Path_1006'
              data-name='Path 1006'
              d='M20.206,6.311A5.249,5.249,0,0,0,16.336,4.8H8.744A5.049,5.049,0,0,0,3.4,10.144V17.7a5.3,5.3,0,0,0,1.548,3.943,5.379,5.379,0,0,0,3.833,1.437H16.3a5.442,5.442,0,0,0,3.87-1.437,5.276,5.276,0,0,0,1.511-3.906V10.144A5.283,5.283,0,0,0,20.206,6.311Zm-.147,11.425a3.6,3.6,0,0,1-1.069,2.69,3.81,3.81,0,0,1-2.69.958H8.781a3.81,3.81,0,0,1-2.69-.958,3.7,3.7,0,0,1-1-2.727V10.144a3.674,3.674,0,0,1,1-2.69A3.748,3.748,0,0,1,8.781,6.5h7.592a3.674,3.674,0,0,1,2.69,1,3.8,3.8,0,0,1,1,2.653v7.592Z'
              transform='translate(-3.4 -4.8)'
            />
          </g>
        </g>
      </symbol>
      <symbol
        id='service-modal-icon'
        data-name='Component 6 – 6'
        xmlns='http://www.w3.org/2000/svg'
        width='40'
        height='40'
        viewBox='0 0 60 60'
      >
        <g id='Component_4_3' data-name='Component 4 – 3'>
          <g id='Group_3541' data-name='Group 3541'>
            <rect id='Rectangle_647' data-name='Rectangle 647' width='60' height='60' rx='30' fill='#101010' />
          </g>
        </g>
        <g id='Component_5_2' data-name='Component 5 – 2' transform='translate(17 17)'>
          <rect
            id='Rectangle_1476'
            data-name='Rectangle 1476'
            width='31.338'
            height='4.018'
            rx='2.009'
            transform='translate(25 2.841) rotate(135)'
            fill='#fff'
          />
          <rect
            id='Rectangle_1477'
            data-name='Rectangle 1477'
            width='31.338'
            height='4.018'
            rx='2.009'
            transform='translate(2.842 0.001) rotate(45)'
            fill='#fff'
          />
        </g>
      </symbol>
      <symbol
        id='config-invoice'
        data-name='Component 6 – 6'
        xmlns='http://www.w3.org/2000/svg'
        width='60'
        height='60'
        viewBox='0 0 60 60'
      >
        <g id='Group_3888' data-name='Group 3888' transform='translate(-1474.885 -405.035)'>
          <path
            id='Path_1103'
            data-name='Path 1103'
            d='M1490.92,405.035a16.035,16.035,0,1,0,16.035,16.035A16.035,16.035,0,0,0,1490.92,405.035Zm10.11,26.145a14.3,14.3,0,1,1,4.188-10.11A14.2,14.2,0,0,1,1501.03,431.18Z'
            fill='#fff'
          />
          <path
            id='Path_1104'
            data-name='Path 1104'
            d='M1546.3,569.779h-1.875a7.159,7.159,0,0,0,5.569,6.924v1.98h1.677v-3.437A5.463,5.463,0,0,1,1546.3,569.779Z'
            transform='translate(-61.589 -145.918)'
            fill='#fff'
          />
          <path
            id='Path_1105'
            data-name='Path 1105'
            d='M1559.908,452.012c-1.042-.083-2-.336-2.025-1-.027-.626.818-.871,1.869-.871,1.75,0,1.943.769,2.021,1.37h5.019c0-2.161-.748-5.266-4.521-6.21V443.01h-5.04v2.328a5.837,5.837,0,0,0-4.634,5.637c0,2.647,1.806,5.766,7,6.313,1.859.2,2.868.367,2.868,1.116,0,.86-1.187,1.141-2.715,1.106-2.336-.053-2.725-1.5-2.76-2.013h-1.875c0,1.414,1.04,3.888,4.635,3.888,4.106,0,4.631-2.166,4.631-3.062,0-1.8-1.588-2.64-4.678-2.907-3.936-.341-5.268-2.485-5.268-4.441,0-1.935,1.366-3.969,4.479-4.279v-2.2h1.68v2.2c2.981.324,4.219,2.339,4.242,3.426h-1.692c-.142-.563-1.083-1.856-3.389-1.856-2.191,0-3.744,1.136-3.744,2.706,0,1.618,1.437,2.69,3.8,2.869,5.416.413,6.151,2.852,6.151,4.479,0,.749-.2,4.3-5.366,4.65V466.4h1.683v-1.928a6.461,6.461,0,0,0,5.558-6.149C1567.829,456.432,1567.018,452.576,1559.908,452.012Z'
            transform='translate(-68.832 -33.635)'
            fill='#fff'
          />
        </g>
      </symbol>
      <symbol
        id='config-calculator'
        data-name='Component 6 – 6'
        xmlns='http://www.w3.org/2000/svg'
        width='60'
        height='60'
        viewBox='0 0 60 60'
      >
        <g id='Group_3667' data-name='Group 3667' transform='translate(0 0)'>
          <path
            id='Path_1016'
            data-name='Path 1016'
            d='M12.207,15.724a.707.707,0,0,1-.707-.707V13.956a.707.707,0,0,1,1.413,0v1.061A.707.707,0,0,1,12.207,15.724Z'
            transform='translate(4.793 5.523)'
          />
          <path
            id='Path_1017'
            data-name='Path 1017'
            d='M13.18,16.48H10.707a.707.707,0,0,1,0-1.413H13.18a1.06,1.06,0,0,0,0-2.12h-.707a2.473,2.473,0,0,1,0-4.947h2.473a.707.707,0,0,1,0,1.413H12.473a1.06,1.06,0,0,0,0,2.12h.707a2.473,2.473,0,1,1,0,4.947Z'
            transform='translate(4.173 3.342)'
          />
          <path
            id='Path_1018'
            data-name='Path 1018'
            d='M12.207,9.475a.707.707,0,0,1-.707-.707V7.707a.707.707,0,1,1,1.413,0V8.768A.707.707,0,0,1,12.207,9.475Z'
            transform='translate(4.793 2.918)'
          />
          <path
            id='Path_1019'
            data-name='Path 1019'
            d='M14.52,21.96a8.48,8.48,0,1,1,8.52-8.48A8.509,8.509,0,0,1,14.52,21.96Zm0-15.547a7.067,7.067,0,1,0,7.1,7.067A7.091,7.091,0,0,0,14.52,6.413Z'
            transform='translate(2.48 2.1)'
          />
          <path
            id='Path_1020'
            data-name='Path 1020'
            d='M12.207,26.753a.707.707,0,1,1,0-1.413A14.149,14.149,0,0,0,26.34,11.207a.707.707,0,1,1,1.413,0A15.565,15.565,0,0,1,12.207,26.753Z'
            transform='translate(4.833 4.42)'
          />
          <path
            id='Path_1021'
            data-name='Path 1021'
            d='M28.891,11.377a.709.709,0,0,1-.669-.475A14.118,14.118,0,0,0,14.885,1.413,13.776,13.776,0,0,0,6.169,4.465a.706.706,0,1,1-.885-1.1A15.167,15.167,0,0,1,14.885,0,15.537,15.537,0,0,1,29.56,10.439a.708.708,0,0,1-.437.9A.737.737,0,0,1,28.891,11.377Z'
            transform='translate(2.155)'
          />
          <path
            id='Path_1022'
            data-name='Path 1022'
            d='M11.6,28.321a.717.717,0,0,1-.235-.04A15.529,15.529,0,0,1,1,13.637a15.284,15.284,0,0,1,2.7-8.71.706.706,0,1,1,1.163.8,13.9,13.9,0,0,0-2.446,7.91,14.112,14.112,0,0,0,9.421,13.311.707.707,0,0,1-.235,1.374Z'
            transform='translate(0.413 1.99)'
          />
          <path
            id='Path_1023'
            data-name='Path 1023'
            d='M14.533,26.066a.71.71,0,0,1-.5-.206l-2.827-2.827a.707.707,0,0,1,0-1l2.827-2.827a.707.707,0,0,1,1,1l-2.326,2.326,2.326,2.326a.707.707,0,0,1,0,1A.7.7,0,0,1,14.533,26.066Z'
            transform='translate(4.588 7.934)'
          />
          <path
            id='Path_1024'
            data-name='Path 1024'
            d='M5.9,9.61a.707.707,0,0,1-.683-.523L4.3,5.674.889,6.588A.707.707,0,0,1,.524,5.223l4.1-1.1a.711.711,0,0,1,.865.5l1.1,4.094a.707.707,0,0,1-.5.866A.754.754,0,0,1,5.9,9.61Z'
            transform='translate(0 1.713)'
          />
          <path
            id='Path_1025'
            data-name='Path 1025'
            d='M23.28,10.085a.7.7,0,0,1-.353-.095l-3.672-2.12a.707.707,0,1,1,.707-1.224l3.06,1.767,1.767-3.06a.707.707,0,1,1,1.224.707l-2.12,3.672a.708.708,0,0,1-.612.353Z'
            transform='translate(7.893 2.086)'
          />
        </g>
      </symbol>
      <symbol
        id='config-icon1'
        data-name='Component 6 – 6'
        xmlns='http://www.w3.org/2000/svg'
        width='8.717'
        height='5.386'
        viewBox='0 0 8.717 5.386'
      >
        <path
          id='Path_296'
          data-name='Path 296'
          d='M13.028,21.386l3.331-3.331,3.331,3.331,1.028-1.028L16.358,16,12,20.358Z'
          transform='translate(20.717 21.386) rotate(180)'
          fill='#666'
        />
      </symbol>
    </svg>
  );
}
