import { SET_CARS, SET_CARS_LOADER } from '../const/cars.const';

const initialState = {
  isLoading: true,
  items: [],
};

export default function carsReducr(state = initialState, action) {
  switch (action.type) {
    case SET_CARS_LOADER: {
      state = {
        ...state,
        isLoading: action.payload,
      };
      break;
    }
    case SET_CARS: {
      state = {
        ...state,
        items: action.payload,
        isLoading: false,
      };
      break;
    }
    default:
      return state;
  }
  return state;
}
