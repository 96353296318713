import { takeEvery, call, all, put } from 'redux-saga/effects';
import { fetchHomeNewModels } from '../../../api/cars.api';
import { setCarsLoading, setCars } from '../action/cars.action';
import { START_FETCH_CARS } from '../const/cars.const';

// actions

//api

function* startFetchCarsAsync(actions) {
  let { payload } = actions;
  yield put(setCarsLoading(true));
  try {
    const { data } = yield fetchHomeNewModels(payload);
    yield put(setCars(data));
  } catch (error) {
    yield put(setCarsLoading(false));
    // yield put(setCityLoader(false))
  }
}

function* onStartFetchCars() {
  yield takeEvery(START_FETCH_CARS, startFetchCarsAsync);
}

export default function* carsSaga() {
  yield all([call(onStartFetchCars)]);
}
