import React from "react";
import { withTranslation } from "react-i18next";
import { Route, Routes, Navigate } from "react-router-dom";
import loadable from "@loadable/component";
import "react-toastify/dist/ReactToastify.css";
import "./scss/main.scss";
import Layout from "module/Layout";

const MainContainer = loadable(() => import("./module/main/MainContainer"), {
  fallback: <div className="loaderItem"></div>,
});

const AboutContainer = loadable(() => import("./module/about/AboutContainer"), {
  fallback: <div className="loaderItem"></div>,
});

const ModelsContainer = loadable(
  () => import("./module/models/ModelsContainer"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const ModelDetailContainer = loadable(
  () => import("./module/models/ModelDetailContainer"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const ServiceContainer = loadable(
  () => import("./module/service/ServiceContainer"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const ServiceOrderContainer = loadable(
  () => import("./module/service/ServiceOrderContainer"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const ArticlesContainer = loadable(
  () => import("./module/article/ArticlesContiner"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const ArticleDetailContainer = loadable(
  () => import("./module/article/ArticleDetailContainer"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const TradeinContainer = loadable(
  () => import("./module/tradein/TradeinContainer"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const TradeDetailContainer = loadable(
  () => import("./module/tradein/TradeDetailContainer"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const ContactContainer = loadable(
  () => import("./module/contact/ContactContainer"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const ContactUsContainer = loadable(
  () => import("./module/contactus/ContactUsContainer"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const CallAggentContainer = loadable(
  () => import("./module/callagent/CallAggentContainer"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const ModificationContainer = loadable(
  () => import("./module/modification/Modification"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const ConfigContainer = loadable(
  () => import("./module/configuration/Config"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);
const ServiceOrdered = loadable(
  () => import("./module/service/ServiceOrdered"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);
const CarModel = loadable(() => import("./module/carModel/CarModel"), {
  fallback: <div className="loaderItem"></div>,
});

const OffersContainer = loadable(
  () => import("./module/offers/OffersContainer"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const OfferDetailContainer = loadable(
  () => import("./module/offers/OfferDetailContainer"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);
const TestDriveContainer = loadable(
  () => import("./module/test-drive/TestDriveContainer"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

const TestDriveSuccess = loadable(
  () => import("./module/test-drive/TestDriveSuccess"),
  {
    fallback: <div className="loaderItem"></div>,
  }
);

function App({ i18n }) {

 const redirectToOfferIndex = () => {
    window.location.href = '/offer/index.html';
    return null;
  };
  
  return (
    <Routes>
      <Route path="/" element={<Layout i18n={i18n} />}>
        <Route index element={<MainContainer />} />
        <Route exact path="/about" element={<AboutContainer />} />
        <Route exact path="/models" element={<ModelsContainer />} />
        <Route exact path="/models/:id" element={<ModelDetailContainer />} />
        <Route exact path="/service" element={<ServiceContainer />} />
        <Route
          exact
          path="/service/order"
          element={<ServiceOrderContainer />}
        />
        <Route
          exact
          path="/service/order/:id"
          element={<ServiceOrderContainer />}
        />
        <Route
          exact
          path="/service/order/:id/success"
          element={<ServiceOrdered />}
        />
        <Route exact path="/news" element={<ArticlesContainer />} />
        <Route exact path="/news/:id" element={<ArticleDetailContainer />} />
        <Route exact path="/tradein" element={<TradeinContainer />} />
        <Route exact path="/tradein/:id" element={<TradeDetailContainer />} />
        <Route exact path="/contact" element={<ContactContainer />} />
        <Route exact path="/feedback" element={<ContactUsContainer />} />
        <Route exact path="/call-agent" element={<CallAggentContainer />} />
        <Route exact path="/modification" element={<ModificationContainer />} />
        <Route exact path="/config/:carId" element={<ConfigContainer />} />
        <Route exact path="/offers" element={<OffersContainer />} />
        <Route exact path="/offers/:id" element={<OfferDetailContainer />} />
        <Route exact path="/test-drive/:id" element={<TestDriveContainer />} />
        <Route exact path="/test-drive" element={<TestDriveContainer />} />
        <Route path="/model/:modelName/:carId" element={<CarModel />} />
        <Route path="/test-drive/:name/success" element={<TestDriveSuccess />} />
         <Route path="/offer" element={<Navigate to="/offer/index.html" />} />
      </Route>
    </Routes>
  );
}

export default withTranslation()(App);
