import fetch from './fetch';

export const fetchAllNews = (page) => fetch().get(`/news/findall?page=${page}`);

export const fetchNewsById = (id) => fetch().get(`/news/get/${id}`);

export const fetchPromos = (id) => fetch().get(`/news/prom/findall`);

export const fetchSearchHome = (search) => fetch().get(`/home/search?search=${search}`);

export const fetchAllCarNews = (carid, page = 0) => fetch().get(`/news/findallbycar/${carid}?page=${page}`);
