import fetch from './fetch';

export const fetchAllServices = () => fetch().get(`/services/findall`);

export const orderSevice = ({ serviceid, email, name, phone, textMessage }) => {
  let payload = new FormData();
  payload.append('serviceid', serviceid);
  payload.append('email', email);
  payload.append('name', name);
  payload.append('phone', phone);
  payload.append('textMessage', textMessage);

  return fetch().post(`/services/register/${serviceid}`, payload);
};
