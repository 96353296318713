import { SET_CARS, SET_CARS_LOADER, START_FETCH_CARS } from '../const/cars.const';

export const startFetchCars = (payload = {}) => ({
  type: START_FETCH_CARS,
  payload,
});

export const setCarsLoading = (payload) => ({
  type: SET_CARS_LOADER,
  payload,
});

export const setCars = (payload) => ({
  type: SET_CARS,
  payload,
});
