import {takeEvery,call,all,put} from "redux-saga/effects"
import { fetchAllSliders } from "../../../api/slider.api"
import { setSlaiders, setSliderLoading } from "../action/slider.action"


// actions

//api

import { START_FETCH_SLIDER } from "../const/slider.const"

function* startFetchSliderAsync(actions){
    yield put(setSliderLoading(true))
    try {
        const {data} = yield fetchAllSliders()
        yield put(setSlaiders(data))
        
    } catch (error) {
        yield put(setSliderLoading(false))
        // yield put(setCityLoader(false))
    }
}

function* onStartFetchSlider(){
   yield takeEvery(START_FETCH_SLIDER,startFetchSliderAsync)
}


export default function* sliderSaga(){
    yield all([
        call(onStartFetchSlider)
    ])
}
