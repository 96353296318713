import { createContext } from "react";

export const currencyOptions = {
  option1: "$",
  option2: "₾",
};

export const GlobalContext = createContext({
  currency: currencyOptions.option1,
  setCurrency: () => {},
});
