import { takeEvery, call, all, put } from 'redux-saga/effects';
import { fetchAllServices } from '../../../api/services.api';
import { setService, setServiceLoader } from '../action/services.action';
import { START_FETCH_SERVICES } from '../const/service.const';
// actions

//api

function* startFetchServicesAsync(actions) {
  yield put(setServiceLoader(true));
  try {
    const { data } = yield fetchAllServices();
    yield put(setService(data));
  } catch (error) {
    yield put(setServiceLoader(false));
    // yield put(setCityLoader(false))
  }
}

function* onStartFetchServices() {
  yield takeEvery(START_FETCH_SERVICES, startFetchServicesAsync);
}

export default function* servicesSaga() {
  yield all([call(onStartFetchServices)]);
}
