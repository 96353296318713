export const START_FETCH_NEWS = 'START_FETCH_NEWS';
export const SET_NEWS_LOADING = 'SET_NEWS_LOADING';
export const SET_NEWS = 'SET_NEWS';

export const START_FETCH_CATEGORIES = 'START_FETCH_CATEGORIES';
export const SET_CATEGORIES_LOADING = 'SET_CATEGORIES_LOADING';
export const SET_CATEGORIES = 'SET_CATEGORIES';

export const START_FETCH_PROMOS = 'START_FETCH_PROMOS';
export const SET_PROMOS_LOADING = 'SET_PROMOS_LOADING';
export const SET_PROMOS = 'SET_PROMOS';

export const START_FETCH_CAR_NEWS = 'START_FETCH_CAR_NEWS';
