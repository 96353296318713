import {START_FETCH_SERVICES,SET_SERVECES_LOADER,SET_SERVICES} from "../const/service.const"

export const startFetchService = ()=>({
    type:START_FETCH_SERVICES
})

export const setServiceLoader = (payload)=>({
    type:SET_SERVECES_LOADER,
    payload
})

export const setService = (payload)=>({
    type:SET_SERVICES,
    payload
})