import {
  SET_NEWS,
  SET_NEWS_LOADING,
  START_FETCH_CATEGORIES,
  START_FETCH_NEWS,
  START_FETCH_PROMOS,
  SET_PROMOS_LOADING,
  SET_PROMOS,
  START_FETCH_CAR_NEWS,
} from '../const/news.const';

export const startFetchNews = (page = 0) => ({
  type: START_FETCH_NEWS,
  payload: page,
});

export const setNewsLoading = (payload) => ({
  type: SET_NEWS_LOADING,
  payload,
});

export const setNews = (payload) => ({
  type: SET_NEWS,
  payload,
});

export const startFetchCategories = () => ({
  type: START_FETCH_CATEGORIES,
});

export const startFetchPromos = () => ({
  type: START_FETCH_PROMOS,
});

export const setPromoLosder = (payload) => ({
  type: SET_PROMOS_LOADING,
  payload,
});

export const setPromos = (payload) => ({
  type: SET_PROMOS,
  payload,
});

export const startFetchCarNews = (carId, page = 0) => ({
  type: START_FETCH_CAR_NEWS,
  payload: { carId, page },
});
