import { combineReducers } from 'redux';
import SliderReducer from './slider.reducer';
import NewsReducer from './news.reducer';
import CarsReducer from './cars.reducer';
import ServicesReducer from "./services.reducer"

export default combineReducers({
  slider: SliderReducer,
  news: NewsReducer,
  cars: CarsReducer,
  services:ServicesReducer
});
