import React, { useState } from "react";
import cn from "classnames";

import { useContext } from "react";

import { GlobalContext, currencyOptions } from "context/GlobalContext";

import "./CurrencySwitch.css";

export const CurrencySwitch = () => {
  const { currency, setCurrency } = useContext(GlobalContext);

  return (
    <div className="switch">
      <div
        onClick={() => setCurrency(currencyOptions.option2)}
        className={cn("switch-item", {
          ["switch-item-active"]: currency === currencyOptions.option2,
        })}
      >
        {currencyOptions.option2}
      </div>
      <div
        onClick={() => setCurrency(currencyOptions.option1)}
        className={cn("switch-item", {
          ["switch-item-active"]: currency === currencyOptions.option1,
        })}
      >
        {currencyOptions.option1}
      </div>
    </div>
  );
};
