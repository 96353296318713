import React from "react";
import { useEffect } from "react";

const useScript = (url, widgetCode) => {
  useEffect(() => {
    //chat
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");

    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

    //title
    const scriptTranslations = document.createElement("script");
    scriptTranslations.setAttribute("type", "text/javascript");

    let codeTranslations = `$zoho.salesiq.ready=function(embedinfo)
    {
    $zoho.salesiq.floatwindow.onlinetitle('ონლაინ ჩატი')
    $zoho.salesiq.floatwindow.offlinetitle('ონლაინ ჩატი')
    $zoho.salesiq.customfield.add({
      "name":"_default.name",
      "hint":"სახელი (აუცილებელი)"
      })
    $zoho.salesiq.customfield.add({
      "name":"_default.question",
      "hint":"აკრიფეთ შეტყობინება და დააჭირეთ Enter-ს"
      })
      $zoho.salesiq.customfield.add(
        {
        "name":"_default.department",
        "hint":"აირჩიეთ დეპარტამენტი" ,
        "required":"false"
        })
    }
    `;

    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);

    scriptTranslations.appendChild(document.createTextNode(codeTranslations));
    document.body.appendChild(scriptTranslations);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(scriptTranslations);
    };
  }, [url]);
};

export default function ZohoChat() {
  return (
    <React.Fragment>
      {useScript(
        "https://salesiq.zohopublic.com/widget",
        "siqbc40a338ae44ae0fa05ceaf0305808f1eba2dc9eb2cd19fdc55ee6269af85f22"
      )}
    </React.Fragment>
  );
}
