import { takeEvery, call, all, put } from 'redux-saga/effects';
import { fetchAllNews, fetchPromos, fetchAllCarNews } from '../../../api/news.api';
import { setNews, setNewsLoading, setPromoLosder, setPromos } from '../action/news.action';
import { START_FETCH_NEWS, START_FETCH_PROMOS, START_FETCH_CAR_NEWS } from '../const/news.const';

// actions

//api

function* startFetchNewsAsync(actions) {
  let { payload } = actions;
  yield put(setNewsLoading(true));
  try {
    const { data } = yield fetchAllNews(payload);
    yield put(setNews(data));
  } catch (error) {
    yield put(setNewsLoading(false));
    // yield put(setCityLoader(false))
  }
}

function* onStartFetchNews() {
  yield takeEvery(START_FETCH_NEWS, startFetchNewsAsync);
}

function* startFetchPromosAsync(actions) {
  yield put(setPromoLosder(true));
  try {
    const { data } = yield fetchPromos();
    yield put(setPromos(data));
  } catch (error) {
    yield put(setPromoLosder(false));
    // yield put(setCityLoader(false))
  }
}

function* onStartFetchPromos() {
  yield takeEvery(START_FETCH_PROMOS, startFetchPromosAsync);
}

function* startFetchCarNewsAsync(actions) {
  yield put(setNewsLoading(true));
  try {
    const { carId } = actions.payload;
    const { data } = yield fetchAllCarNews(carId);
    yield put(setNews(data));
  } catch (error) {
    yield put(setNewsLoading(false));
    // yield put(setCityLoader(false))
  }
}

function* onStartFetchCarNews() {
  yield takeEvery(START_FETCH_CAR_NEWS, startFetchCarNewsAsync);
}

export default function* newsSaga() {
  yield all([call(onStartFetchNews), call(onStartFetchPromos), call(onStartFetchCarNews)]);
}
